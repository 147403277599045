import {loadStripe} from '@stripe/stripe-js';
import React, { useCallback, useState, useEffect } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
    Navigate
  } from "react-router-dom";
import { createCheckoutSession, getSessionStatus } from './index.ts';

const stripePromise = loadStripe("pk_live_51P6j9906QKdm0sZ4aS5jrPJBFRpLN6LRKSgIQdERVstDpLwKbc0b9Oz7HXuyvrjEuHUW35w1DTi2P4KLg2rzRmfr000Vj94BE4");

export const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('')
  useEffect(() => {
    createCheckoutSession().then((res) => {
        setClientSecret(res.clientSecret)
    })
  }, [])

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{clientSecret}}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    getSessionStatus(sessionId)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:capper.central5@gmail.com">capper.central5@gmail.com</a>.
        </p>
      </section>
    )
  }

  return null;
}

