import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DBBButton from '../globals/DBBButton.jsx';
import useWindowSize from '../globals/useWindowResize.js';

function BettingInfo({bets}) {
  let { id } = useParams();
  const [betsByDate, setBetsByDate] = useState({});
  const [activeSport, setActiveSport] = useState('all');
  const [availableSports, setAvailableSports] = useState([]);
  const [width, height] = useWindowSize()
  const [tableWidth, setTableWidth] = useState()

    useEffect(() => {
      if (width < 768){
        setTableWidth(0.95*width)
      } else {
        setTableWidth(600)
      }
      
    }, [width])


  const cleanDate = (d) => {
    return d.split("T")[0]
  }
  // Fetch bets on component mount
  useEffect(() => {
    const sports = Array.from(new Set(bets.map(bet => bet.sport)));
        setAvailableSports(sports);
    const betsOrganizedByDate = {};
    bets.forEach(bet => {
      betsOrganizedByDate[cleanDate(bet.date)] = betsOrganizedByDate[cleanDate(bet.date)] || [];
      betsOrganizedByDate[cleanDate(bet.date)].push(bet);
    });
    setBetsByDate(betsOrganizedByDate);
  }, [id, bets]);

  useEffect(() => {

  })

  // Filter bets by the selected sport
  
  return (
    <div style={{marginBottom: 100, width: tableWidth, padding: 0.02*width, marginLeft: "auto", marginRight: "auto"}}>
      {/* Tab selection for sports */}
      <div>
        <DBBButton underline={activeSport === 'all'} onClick={() => setActiveSport('all')}>All Sports</DBBButton>
        {availableSports.map((sport) => (

          <DBBButton key={sport} underline={activeSport === sport} onClick={() => setActiveSport(sport)}>
            {sport.charAt(0).toUpperCase() + sport.slice(1)}
          </DBBButton>
        ))}
      </div>
      {/* Bets Table */}
      {Object.keys(betsByDate).map((date) => {
          // Filter bets for the current date based on the selected sport
          const filteredBets = betsByDate[date].filter(bet => activeSport === 'all' || bet.sport === activeSport);
          
          // Only render the date and bets if there are filtered bets to show
          return filteredBets.length > 0 ? (
            <div key={date}>
              <h2 style={{ position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px 0', zIndex: 1 }}>{date}</h2>
              <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0' }}>
                <thead
                  style={{textAlign: "center"}}
                >
                  <tr>
                    <th style={{width: "50%"}} >Bet</th>
                    <th>Profit</th>
                    <th>League</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBets.map((bet, index) => (
                    <tr key={index} style={{ borderBottom: '2px solid #000' }}>
                      <td style={{textAlign: "center", width: "50%"}}>{bet.description}</td>
                      <td style={{textAlign: "center", color: bet.outcome === "W" ? "green" : "red"}} >{bet.outcome === "W" ? `+${Math.round(bet.amount_staked * bet.odds -  bet.amount_staked, 2)}` : `-${Math.round(bet.amount_staked, 2)}`}</td>
                      <td style={{textAlign: "center"}}>{bet.sport.toUpperCase()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null;
        })}
      </div>
  );
}

export default BettingInfo;
