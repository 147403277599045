import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { getCappers } from "../api/index.ts";
import { InlineBannerWrap } from "../globals/static";
import DBBButton from "../globals/DBBButton";
import { useNavigate } from "react-router-dom"
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

export default function SearchBox() {
    const [cappers, setCappers] = useState()
    const [search, setSearch] = useState()
    const nav = useNavigate()

    useEffect(() => {
        const allCappers = async () => {
        try {
            const res = await getCappers()
            const finalArray = []
            res.forEach(element => {
                finalArray.push({"label": element.name, "id": element.id})
            });
            setCappers(finalArray);
        } catch (e) {
            console.log(e);
        }
    };
    allCappers()
    }, [])

    const handleSearch = () => {
        if (search){nav(`/cappers/${search}`)}
    }
    
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
            mode: "dark",
            }});

    const outerTheme = useTheme();
    return (
        <InlineBannerWrap>
        <ThemeProvider theme={customTheme(outerTheme)}>
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={cappers}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Find a Capper" />}
            onChange={(event, value) => setSearch(value.id)}
            />
        </ThemeProvider>
            <DBBButton onClick={handleSearch} color="primary">View</DBBButton>
        </InlineBannerWrap>
        
    );
}