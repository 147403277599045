import { Card } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { dbbText, CardHeader, dbbSecond } from "../globals/static"
import useWindowSize from '../globals/useWindowResize';
import SearchBox from './SearchBox';
import { useEffect, useState } from 'react';

export default function SearchBoxCard(){
    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()
    const [cardHeight, setCardHeight] = useState()
    const [cardMargin, setCardMargin] = useState()

    useEffect(() => {
      if (width < 768){
        setCardWidth(0.75*width)
        setCardMargin(0.005*width)
        setCardHeight(0.45*height)
      } else {
        setCardWidth(300)
        setCardHeight(250)
        setCardMargin(0.05*height)
      }
      
    }, [height, width])

    const theme = createTheme({
        components: {
          // Name of the component
          MuiCard: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                borderColor: dbbText,
                borderStyle: "solid",
                padding: "1rem",
                borderRadius: "16px",
                backgroundColor: dbbSecond,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "visible",
                width: cardWidth,
                height: cardHeight
              },
            },
          },
        },
      });
    return (
    <ThemeProvider theme={theme}>
        <Card>
            <CardHeader style={{fontSize: "24px"}}>
                Find any capper we track!
            </CardHeader>
            <SearchBox />
        </Card>
    </ThemeProvider>
    )
}