import styled from 'styled-components';

// Current color
// export const dbbMain = "#fff"
// export const dbbSecond = "#F7FDE9"
// export const dbbThird = "#EFFBF3"
// export const dbbAccent = "#045151"
// export const dbbText = "#2E3233"
// export const dbbBackground2 = "#c6efce"

// export const dbbMain = "#F0F4EF"
// export const dbbSecond = "#B4CDED"
// export const dbbThird = "#344966"
// export const dbbAccent = "#045151"
// export const dbbText = "#0D1821"
// export const dbbBackground2 = "#BFCC94"

export const dbbMain = "#fffcf2"
export const dbbSecond = "#003049"
export const dbbThird = "#F3A712"
export const dbbAccent = "#E4572E"
export const dbbText = "#fffcf2"
export const dbbBackground2 = "#045151"

export const dbbFontMain = "'Poppins', sans-serif"
export const dbbFontSecond = "'Poppins', sans-serif"

export const MainWrap = styled.div `
margin: 2vw;
font-family: ${dbbFontMain};
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
`

export const StatsWrap = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
width: 100%;
max-width: 500px;
`

export const ProfileWrap = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
width: 100%;
`

export const ButtonWrap = styled.div`
margin-left: 3 rem;
`

export const StyledHeader = styled.header`
display: flex;
justify-content: space-between;
padding: 1vw;
align-items: center;
background-color:  ${dbbSecond};
width: 98vw;
font-family: 'Poppins', sans-serif;
margin-top: 0;
height: 15%;
position: sticky;
top: 0;
z-index: 1100;
`

export const CardWrap = styled.div`
align-items: center;
max-width: 500px;
overflow: scroll;
height: 50vh;
font-family: ${dbbFontSecond};
font-size: 16px;
background-color: ${dbbBackground2};
border-radius: 8px;
`
export const SingleCardWrap = styled.div`
min-height: 30vh;
padding: 5px;
font-family: ${dbbFontSecond};
font-size: 16px;
`

export const InlineWrap = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: baseline;
max-width: 80%;
`

export const CardHeader = styled.h2`
color: ${dbbText};
font-family: ${dbbFontMain};
font-weight: 300;
text-align: center;
`

export const CardROIHeader = styled.h2`
color: ${dbbText};
font-family: ${dbbFontMain};
font-weight: 400;
text-align: center;
margin: 0;
`

export const CardROIWrap = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
`


export const CardROIBody = styled.p`
color: ${dbbText};
font-family: ${dbbFontMain};
font-weight: 200;
text-align: center;
margin: 0;
`

export const CardModalHeader2 = styled.h3`
color: ${dbbSecond};
margin-left: 20px
`

export const CardHeader2 = styled.h3`
color: ${dbbText};
margin: 1px
`

export const CardHeader3 = styled.h4`
color: ${dbbText};
margin: 1px
`

export const CardText = styled.p`
margin: 1px
`

export const MainTextWrap = styled.div`
display: flex;
flex-direction: column;
flex-wrap: nowrap;
align-items: baseline;
max-width: 70%;
`

export const StatsHeader = styled.h3`
margin: 5px;
font-family: ${dbbFontMain};
font-size: medium;
font-weight: 500;
`

export const StatsCardWrap = styled.div`
max-width: 500px;
font-size: 16px;
border-radius: 8px;
`

export const InlineStatsWrap = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
align-items: baseline;
max-width: 90%;
`

export const ProfileHeader = styled.h2`
text-align: center;
font-family: ${dbbFontSecond};
font-size: xx-large;
font-weight: 600;
color: ${dbbSecond}
`

export const HomeBanner = styled.div`
width: 100vw;
background-color:  ${dbbSecond};
`

export const BannerCardWrap = styled.div`
height: 70%;
align-items: center;
display: flex;
flex-direction: row;
justify-content: center;
`

export const HomeBanner2 = styled.div`
width: 100vw;
height: 70vh;
background-color:  ${dbbSecond};
`

export const Banner2CardWrap = styled.div`
height: 70%;
padding: 10%;
max-width: 600px;
`

export const HomeBanner3 = styled.div`
width: 100vw;
background-color:  ${dbbMain};
`

export const MainBanner = styled.div`
width: 100vw;
display: flex;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
flex-direction: column;
`

export const Banner3CardWrap = styled.div`
height: 70%;
align-items: center;
display: flex;
flex-direction: row;
justify-content: space-evenly;
flex-wrap: wrap;
`

export const InlineBannerWrap = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
align-items: flex-end;
`