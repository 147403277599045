import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Header from './components/Header'; // Adjust the import path based on your file structure
import MainPage from './components/MainPage.jsx';
import Home from './components/Home.jsx';
import LoginModal from './components/LoginModal.jsx';
import { useState } from 'react';
import DBBButton from './globals/DBBButton.jsx';
import SignUpModal from './components/SignUpModal.jsx';
import { CheckoutForm } from './api/stripeUtils.js';


function App() {
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const handleOpen = () => setOpenLoginModal(true)
  const handleClose = () => setOpenLoginModal(false); // Added handleClose function

  const [openSignModal, setOpenSignModal] = useState(false)
  const handleSignOpen = () => {setOpenSignModal(true)}
  const handleSignClose = () => setOpenSignModal(false); // Added handleClose function

  const LoginButton = () => (
    <DBBButton size='small' color='primary' onClick={handleOpen}>
      Capper Login
    </DBBButton>
  );

  const SignUpButton = () => (
    <DBBButton size='small' color='primary' onClick={handleSignOpen}>
      Become a Capper
    </DBBButton>
  )

  return (
    <Router>
      <div>
      <Header LoginButton={<LoginButton />} SignUpButton={<SignUpButton />}/>
        <main>
        <LoginModal openModal={openLoginModal} handleClose={handleClose}/>
        <SignUpModal openModal={openSignModal} handleClose={handleSignClose} />
          <Routes>
            <Route path="/cappers/:id" element={<MainPage />} />
            <Route path ="/" element={<Home />}/>
            <Route path="/checkout" element={<CheckoutForm />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
