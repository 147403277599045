import Banner from "./Banner";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";

export default function Home(){
    return(
        <>
        <Banner2 />
        <Banner3 />
        </>
    )
}