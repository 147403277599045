import { getCapperBets, getCapperProfile, getCapperStats } from '../api/index.ts';
import React, { useEffect, useState } from 'react';
import { dbbAccent, ProfileHeader, Banner3CardWrap, ProfileWrap, MainBanner, dbbFontSecond } from '../globals/static';
import { useParams } from "react-router-dom";
import useWindowSize from '../globals/useWindowResize.js';
import BettingInfo from './betTable.jsx';
import MainPageCard from './MainPageCard.jsx';
import MainPageROICard from './MainPageROICard.jsx'

export default function MainPage(){

    let { id } = useParams();
    const [capperProfile, setCapperProfile] = useState()

    const [record, setRecord] = useState("")
    const [perc, setPerc] = useState("")
    const [profit, setProfit] = useState("")
    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()
    const [fontSize, setFontSize] = useState("xx-large")
    const [profileWrapWidth, setProfileWrapWidth] = useState(400)
    const [rawVals, setRawVals] = useState({})
    const [bets, setBets] = useState([])

    useEffect(() => {
        if (width < 768){
            setCardWidth("1rem")
            setFontSize("large")
            setProfileWrapWidth(0.8*width)
            } else {
            setCardWidth("10%")
            }
            
    }, [width])

    const recordVals = (vals) => {
      return vals.split("-")[0] - vals.split("-")[1]
    }
    const percVals = (perc) => {
      return perc.split("%")[0]
    }

    useEffect(() => {
        getCapperProfile(id).then((res) => setCapperProfile(res))
        getCapperBets(id).then((res) => setBets(res))
        getCapperStats(id).then((res) => {
          setRecord(res.record ? res.record : "0 - 0 - 0");
          setPerc(res.percentage ? res.percentage : "0%");
          setProfit(res.units ? res.units : "+0")
          setRawVals(
            {
              profit: res.units,
              perc: percVals(res.percentage),
              record: recordVals(res.record)
            }
          )
        })
    }, [id])

    const calcROI = () => {
      let value = 0;
      let cost = 0;
      bets.forEach((bet) => {
        cost += Number(bet.amount_staked);
        if (bet.outcome === "W") {
          value += Number(bet.amount_staked) * Number(bet.odds) - Number(bet.amount_staked);
        }
      });
      return cost === 0 ? 0 : Math.round((value / cost) * 100) / 100;
    };
    

    return (<>
     {capperProfile === "failed" || capperProfile === undefined ? (<p style={{"color": dbbAccent}}>
         {capperProfile}</p>):(
          <MainBanner>
              <ProfileWrap style={{marginTop: "10%"}}>
                <ProfileHeader style={{fontSize: `${ height * 0.05}px`}}>{capperProfile?.name}</ProfileHeader>
                <ProfileHeader style={{fontSize: fontSize}}>
                <a href={capperProfile?.url} style={{fontFamily: dbbFontSecond}}>
                  Join {capperProfile?.name} now!
                </a>
                </ProfileHeader>
              </ProfileWrap>
              <Banner3CardWrap style={{padding: cardWidth}}>
              <MainPageROICard title={`Profits`} values={[`Units: ${rawVals.profit > 0 ? "+" : ""}${profit}       `, `ROI: ${calcROI() > 0 ? "+" : ""}${calcROI()}%`]}/>
              <MainPageCard title={`Win Percentage: ${perc}`}/>
              <MainPageCard title={`Capper Record: ${record}`}/>
            </Banner3CardWrap>
            </MainBanner>
             
            
         )}
    <ProfileHeader>Bet History</ProfileHeader>
    <BettingInfo bets={bets} />
    </>
    )
}

{/* <CardWrap> {
   bets !== "failed" ?
(bets?.map((object) => 
<DBBCard 
bet={object.description} 
             outcome={object.outcome} 
             sport={object.sport} 
           amount={object.amount_staked}
             odds={object.odds}
             type={object.bet}
             date={cleanDate(object.date) + " EST"}
             />   
         )):null
       }
     </CardWrap> */}