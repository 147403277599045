import React from 'react';
import { dbbAccent, dbbThird, dbbFontSecond, dbbSecond, dbbMain } from './static';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const DBBButton = ({ children, color, size, onClick, underline }) => {
  // Define basic color styles
  const colorStyles = {
    primary: { backgroundColor: dbbSecond, color: dbbMain},
    secondary: { backgroundColor: dbbSecond, color: dbbAccent},
    danger: { backgroundColor: '#dc3545', color: '#fff' },
  };

  

  // Define size styles
  const sizeStyles = {
    small: { fontSize: '12px', padding: '5px 10px' },
    medium: { fontSize: '14px', padding: '10px 20px' },
    large: { fontSize: '16px', padding: '15px 30px' },
  };

  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            ...colorStyles[color],
            ...sizeStyles[size],
            margin: "5px",
            fontFamily: dbbFontSecond,
            cursor: 'pointer',
            outline: 'none',
            textDecoration: underline ? 'underline' : 'none'
          },
        },
      },
    },
  });
  return (
  <ThemeProvider theme={theme}>
    <Button onClick={onClick}>
      {children}
    </Button>
  </ThemeProvider>
  );
};

export default DBBButton;
