import React from 'react';
import { ButtonWrap, StyledHeader,  dbbText, dbbFontMain } from '../globals/static';
import DBBButton from "../globals/DBBButton"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicMenu from './NavMenu';

export default function Header({LoginButton, SignUpButton}){
  const activePage = window.location.pathname
  const [dbbToken, setDbbToken] = useState(localStorage.getItem('dbbToken'))

  // Inline style for active menu item
  const activeStyle = {
    color: dbbText, // Highlight color
    textDecoration: 'underline',
  };

  const inactiveStyle = {
    color: dbbText, // Highlight color
    textDecoration: 'none',
  };

  const finalStyle = activePage === '/' ? activeStyle : inactiveStyle

  const styles = {
    ...finalStyle,
    fontFamily: dbbFontMain,
    fontSize: '20px',
  }

  const nav = useNavigate()

  return (
    <StyledHeader>
      <nav style={{ display: 'flex', gap: '20px' }}>
        {/* Home link */}
        <a
        href="/"
          style={styles}
          onMouseOver={(e) => e.currentTarget.style.textDecoration = activePage.textDecoration}
          onMouseOut={(e) => e.currentTarget.style.textDecoration = finalStyle.textDecoration}
        >
          Home
        </a>
      </nav>
      { dbbToken ? (<ButtonWrap>
        {/* Login button */}
        <DBBButton size='small' color='secondary' onClick={() => {localStorage.removeItem('dbbToken'); setDbbToken(''); nav("/")}}>Logout</DBBButton>
        </ButtonWrap>
        ):(
        <ButtonWrap>
        {/* Login button */}
        {LoginButton}
        {/* SignUp button */}
        {SignUpButton}
      </ButtonWrap>
      )
      }
      
    </StyledHeader>
  );
};
