import { HomeBanner3, Banner3CardWrap, CardHeader3, InlineStatsWrap, CardHeader } from "../globals/static"
import useWindowSize from '../globals/useWindowResize';
import { useEffect, useState } from 'react';
import TextCard from "./TextCard";
import SearchBoxCard from "./SearchBoxCard";


// At Capper Central, we're not just about tracking bets; 
// we're about enhancing your betting strategy. With comprehensive bet 
// tracking, we equip you with all the tools you need to bet like a pro. Become a savvy bettor and start maximizing your returns today!

const cardOneValue = "We equip you with all the tools you need to bet like a pro. Become a savvy bettor and start maximizing your returns today!"
const cardOneTitle = "Unlock your winning potential"
const cardTwoValue = "We provide comprehensive bet tracking to help you find the best cappers and enhance your betting strategy"
const cardTwoTitle = "Choose the best Cappers"

export default function Banner3(){

    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()

    useEffect(() => {
        if (width < 768){
            setCardWidth("1rem")
            } else {
            setCardWidth("10%")
            }
            
    }, [width])
    
    return (
        <HomeBanner3>
            <Banner3CardWrap style={{padding: cardWidth}}>
                <TextCard textValue={cardOneValue} title={cardOneTitle}/>
                <TextCard textValue={cardTwoValue} title={cardTwoTitle}/>
                <SearchBoxCard />
            </Banner3CardWrap>
        </HomeBanner3>
    )
}