import wrapper from './wrapper.ts';

export const getCappers = () => {
    return wrapper("cappers/", "GET")
}

export const getCapperBets = (capperId: number) => {
    return wrapper(`cappers/${capperId}/bets/`, "GET")
}

export const getCapperProfile = (capperId: number) => {
    return wrapper(`cappers/${capperId}/`, "GET")
}

export const loginCapper = (username: string, password: string) => {
    return wrapper(`cappers/login/`, "POST", {
        "username": username,
        "password": password
    })
}

export const createCapper = (email: string, name: string, password: string, url: string) => {
    return wrapper(`cappers/create/`, "POST", {
        "name": name,
        "password": password,
        "email": email,
        "url": url
    })
}

export const updateCapper = (capperId: number, updateObject: object) => {
    return wrapper(`cappers/${capperId}/update/`, "PATCH", updateObject)
}

export const deleteCapper = (capperId: number) => {
    return wrapper(`cappers/${capperId}/delete/`, "DELETE")
}

export const getCapperStats = (capperId: number) => {
    return wrapper(`cappers/${capperId}/stats/`, "GET")
}

export const createCheckoutSession = () => {
    return wrapper("create-checkout-session/", "GET")
}

export const getSessionStatus = (sessionId: string) => {
    return wrapper(`session-status/?session_id=${sessionId}`, "GET")
}