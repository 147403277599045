import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import DBBButton from '../globals/DBBButton';
import { useFormik } from 'formik';
import { loginCapper } from '../api/index.ts';
import * as yup from 'yup';
import { CardModalHeader2 } from '../globals/static.js';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

export default function LoginModal({ openModal, handleClose }) {
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema
  });

  const nav = useNavigate()

  const handleSubmission = () => {
    loginCapper(formik.values.username, formik.values.password).then(
      (res) => {
        localStorage.setItem("dbbToken", res.token)
      }
    ).then(() => handleClose())
  }
  return (
    openModal ? (
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{marginLeft: 12, marginRight: 12}}
      >
        <Box sx={style}>
          <CardModalHeader2>
            Login
          </CardModalHeader2>

        <form onSubmit={formik.handleSubmit} style={{margin: "10px", padding:"5px"}}>
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{marginBottom: 2}}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <DBBButton color="primary" onClick={handleSubmission}>
          Submit
        </DBBButton>
      </form>
        </Box>
      </Modal>
    ): null
  );
}