import { HomeBanner2, Banner2CardWrap, CardHeader3, InlineStatsWrap, CardHeader } from "../globals/static"
import useWindowSize from '../globals/useWindowResize';
import { useEffect, useState } from 'react';

export default function Banner2(){

    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()

    useEffect(() => {
      if (width < 768){
        setCardWidth("1rem")
      } else {
        setCardWidth("10%")
      }
      
    }, [width])


    return (
        <HomeBanner2>
            <Banner2CardWrap style={{padding: cardWidth}}>
                    <CardHeader style={{fontSize: "36px", fontWeight: 600, textAlign: "left"}}>
                    Capper Central is the leading sports bet tracking service.
                    </CardHeader>
                    <CardHeader3 style={{fontSize: "24px", fontWeight: "400", marginBottom: "1rem"}}>
                    Gain transparent access to the world's top handicappers and 
                    expert insights for smarter sports betting decisions.
                    </CardHeader3>
            </Banner2CardWrap>
        </HomeBanner2>
    )
}