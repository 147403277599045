import { Card } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { dbbText, CardHeader, dbbSecond } from "../globals/static"
import useWindowSize from '../globals/useWindowResize';
import { useEffect, useState } from 'react';

export default function MainPageCard({title}){
    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()

    useEffect(() => {
      if (width < 768){
        setCardWidth(0.75*width)
      } else {
        setCardWidth(300)
      }
      
    }, [width])

    const theme = createTheme({
        components: {
          // Name of the component
          MuiCard: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                borderColor: dbbText,
                borderStyle: "solid",
                padding: "1rem",
                borderRadius: "16px",
                backgroundColor: dbbSecond,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "visible",
                width: cardWidth,
                height: 75
              },
            },
          },
        },
      });
    return (
    <ThemeProvider theme={theme}>
        <Card>
            <CardHeader style={{fontSize: "20px"}}>
                {title}
            </CardHeader>
        </Card>
    </ThemeProvider>
    )
}