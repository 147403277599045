import { Card } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { dbbText, CardHeader3, CardHeader, dbbSecond } from "../globals/static"
import useWindowSize from '../globals/useWindowResize';
import { useEffect, useState } from 'react';

export default function TextCard({textValue, title}){
    const [width, height] = useWindowSize()
    const [cardWidth, setCardWidth] = useState()
    const [cardHeight, setCardHeight] = useState()
    const [cardMargin, setCardMargin] = useState()

    useEffect(() => {
      if (width < 768){
        setCardWidth(0.75*width)
        setCardMargin(0.005*width)
        setCardHeight(0.45*height)
      } else {
        setCardWidth(300)
        setCardHeight(250)
      }
      
    }, [height, width])

    const theme = createTheme({
        components: {
          // Name of the component
          MuiCard: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                borderColor: dbbText,
                borderStyle: "solid",
                padding: "1rem",
                borderRadius: "16px",
                backgroundColor: dbbSecond,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "visible",
                width: cardWidth,
                height: cardHeight
              },
            },
          },
        },
      });
    return (
    <ThemeProvider theme={theme}>
        <Card>
            <CardHeader style={{fontSize: "24px"}}>
                {title}
            </CardHeader>
            <CardHeader3 style={{fontSize: "16px", fontWeight: "400", marginBottom: "1rem"}}>
                {textValue}
            </CardHeader3>
        </Card>
    </ThemeProvider>
    )
}