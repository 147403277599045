import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import DBBButton from '../globals/DBBButton';
import { useFormik } from 'formik';
import { createCapper, loginCapper } from '../api/index.ts';
import * as yup from 'yup';
import { CardModalHeader2 } from '../globals/static.js';
import { useNavigate } from 'react-router-dom';
import { CheckoutForm } from '../api/stripeUtils.js';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
  name: yup
    .string('Enter your Company Name')
    .required('Email is required'),
  url: yup
  .string('Enter your website url (can be your Instagram URL)')
  .url('Must be a vbalid URL (can be your Instagram URL)')
});

export default function SignUpModal({ openModal, handleClose }) {

  const nav = useNavigate()
  const [showModal, setShowModal] = useState(openModal)
  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      url: 'https://',
      email: ''
    },
    validationSchema: validationSchema,
  });
  const handleSubmission = () => {
    createCapper(formik.values.email, formik.values.name, formik.values.password, formik.values.url).then((result) => {
        loginCapper(formik.values.email, formik.values.password).then((res) => {
            localStorage.setItem("dbbToken", res.token)
          }).then(
            nav("checkout")
          ).then(
            setShowModal(false)
          )
    })
  }
  return (

    showModal ? (
            <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
        <Box sx={style}>
          <CardModalHeader2>
            Sign Up
          </CardModalHeader2>

        <form onSubmit={formik.handleSubmit} style={{margin: "10px", padding:"5px"}}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Company Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{marginBottom: 2}}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{marginBottom: 2}}
        />
        <TextField
          fullWidth
          id="url"
          name="url"
          label="Website URL"
          value={formik.values.url}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{marginBottom: 2}}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <DBBButton color="primary" onClick={handleSubmission}>
          Submit
        </DBBButton>
      </form>
        </Box>
      </Modal>
    ): null
  );
}